/**** Fonts *****/
@font-face {
  font-family: 'AvenirNextLTPro-Regular';
  src: url('/fonts/AvenirNextLTPro-Regular/AvenirNextLTPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/AvenirNextLTPro-Regular/AvenirNextLTPro-Regular.woff')
      format('woff'),
    url('/fonts/AvenirNextLTPro-Regular/AvenirNextLTPro-Regular.ttf')
      format('truetype'),
    url('/fonts/AvenirNextLTPro-Regular/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AvenirNextLTPro-Bold';
  src: url('/fonts/AvenirNextLTPro-Bold/AvenirNextLTPro-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('/fonts/AvenirNextLTPro-Bold/AvenirNextLTPro-Bold.woff') format('woff'),
    url('/fonts/AvenirNextLTPro-Bold/AvenirNextLTPro-Bold.ttf')
      format('truetype'),
    url('/fonts/AvenirNextLTPro-Bold/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DigitalDismay';
  src: url('/fonts/digital-dismay/digital-dismay.otf');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'AvenirNextLTPro-Regular', sans-serif;
}

button {
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  background: unset;
  color: inherit;
}
