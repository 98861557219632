.react-h5-audio-player {
  background: #1c1e1e;
  box-shadow: 0 -4px 28px 0 #00a0ff;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 0 10px;
  height: 80px;
  z-index: 1;
  width: 100%;

  .rhap_play-pause-button {
    color: #fff;
    background: #00a0ff;
    display: flex;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
  }
  .rhap_progress-container {
    justify-content: center;
    margin: 0;
    align-items: flex-end;
  }
  .rhap_progress-section {
    width: 100%;
    font-weight: 600;
  }
  .rhap_progress-section:focus {
    outline: 0;
  }
  .rhap_download-progress {
    background-color: #535559;
  }
  .rhap_main-controls {
    height: 55px;
    flex-grow: 1;
  }

  .rhap_progress-filled {
    background: #00a0ff;
  }

  .rhap_progress-bar-show-download {
    background-color: #121212;
  }

  .rhap_download-progress {
    background-color: #00a0ff54;
  }

  .rhap_progress-indicator {
    opacity: 0;
  }
  .rhap_total-time {
    color: white;
  }
  .rhap_current-time {
    color: white;
  }
}
.react-h5-audio-player:focus,
.rhap_play-pause-button:focus,
.rhap_progress-container:focus,
.rhap_download-progress:focus,
.rhap_volume-bar-area:focus button:focus {
  outline: 0;
}
.rhap_volume-container {
  width: calc(11% - 10px);
}

.rhap_volume-bar {
  background-color: #00a0ff54;
}
.rhap_volume-filled {
  background-color: #00a0ff;
}

.rhap_volume-indicator {
  background-color: white;
  z-index: 3;
}

.rhap_volume-button {
  color: white;
}

.rhap_main {
  align-items: center;
}

.rhap_controls-section {
  width: 1100px;
}

@media (max-width: 420px) {
  .rhap_volume-container {
    display: none;
  }
  .rhap_progress-section {
    min-width: 300px;
  }
}

@media (max-width: 960px) {
  .rhap_volume-container {
    width: 27%;
    right: 24px;
  }
  .rhap_volume-indicator {
    width: 24px;
    height: 24px;
    top: -8px;
    margin-left: -12px;
  }
  .rhap_volume-bar {
    height: 8px;
  }

  .rhap_volume-button {
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }

  .rhap_volume-button svg {
    width: 1.2em;
    height: 1.2em;
  }
}

@media (max-width: 1200px) {
  .rhap_controls-section {
    width: 80%;
  }
}
